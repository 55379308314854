// src/components/pdfViewer.tsx (assumed path for @pdfViewer)
import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import styles from './pdfViewer.module.css';
import { FormatType } from '@interfaces';
import { fetchPdfViewerInfo,fetchValidPathItems } from '@functions';
import { get_basename } from 'shared-functions';

interface PdfViewerProps {
  rootPath?: string;
  rootString?: string | null;
  initialString?: string | null;
  searchQuery?: string;
}

interface DirectoryEntry {
  doc_id: string;
  root_path: string;
  root_string: string;
}

const PdfViewer = ({
  rootPath = 'pdfs',
  rootString = null,
  initialString = null,
  searchQuery = ''
}: PdfViewerProps): React.ReactNode => {
  const [directories, setDirectories] = useState<string[]>([]);
  const [directoryLinks, setDirectoryLinks] = useState<string[]>([]);
  const [selectedDirIndex, setSelectedDirIndex] = useState<number>(-1);
  const [selectedDirInfo, setSelectedDirInfo] = useState<any>({});
  const [format, setFormat] = useState<any>('images');
  const [availableFormats] = useState<FormatType[]>(['pdf', 'pdf_pages', 'text', 'images']);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorStates, setErrorStates] = useState<{ [key: string]: boolean }>({});
  const [textSearchQuery, setTextSearchQuery] = useState<string>(''); // Correct variable
  const [searchResults, setSearchResults] = useState<{ page: number; snippet: string }[]>([]);
  const [showThumbnails, setShowThumbnails] = useState<boolean>(true);
  const [showText, setShowText] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const userScrolling = useRef(false);
  useEffect(() => {
    async function initialize() {
      try {
        setIsLoading(true);
        let paths: string[] = [];
        let links: string[] = [];

        if (searchQuery) {
          const response = await fetch(`http://localhost:3060/api/jfk_search?q=${encodeURIComponent(searchQuery)}`);
          const data = await response.json();
          if (data.result) {
            paths = data.result.map((entry: DirectoryEntry) => entry.root_path);
            links = data.result.map((entry: DirectoryEntry) => entry.root_string);
          }
        } else {
          const [pathsResponse, linksResponse] = await Promise.all([
            fetchValidPathItems(rootPath, rootString,false,false),
            fetchValidPathItems(rootPath, rootString,true,false)
          ]);

          paths = Array.isArray(pathsResponse) ? pathsResponse : pathsResponse?.result || [];
          links = Array.isArray(linksResponse) ? linksResponse : linksResponse?.result || [];
        }

        if (paths.length > 0 && links.length > 0 && paths.length === links.length) {
          setDirectories(paths);
          setDirectoryLinks(links);

          if (initialString) {
            const initialLower = initialString.toLowerCase();
            const closestIndex = paths.findIndex((path: string) =>
              get_basename(path).toLowerCase().includes(initialLower)
            );
            setSelectedDirIndex(closestIndex >= 0 ? closestIndex : 0);
          } else {
            setSelectedDirIndex(0);
          }
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setIsLoading(false);
      }
    }
    initialize();
  }, [rootPath, rootString, initialString, searchQuery]);
  console.log('PdfViewer rendered:', { rootPath, initialString, searchQuery, selectedDirIndex, textSearchQuery });
  // Search text
  useEffect(() => {
    if (textSearchQuery && selectedDirIndex >= 0 && selectedDirInfo['text']) {
      const searchText = async () => {
        const results: { page: number; snippet: string }[] = [];
        for (let page = 1; page <= totalPages; page++) {
          const textUrl = `${selectedDirInfo['text']}_page_${page}.txt`;
          // Add a check to ensure textUrl is valid before fetching
          if (!textUrl || textUrl === `_page_${page}.txt`) {
            console.warn(`Skipping fetch for empty URL on page ${page}`);
            continue;
          }
          try {
            const response = await fetch(textUrl);
            if (response.ok) {
              const text = await response.text();
              if (text.toLowerCase().includes(textSearchQuery.toLowerCase())) {
                const snippet = text.substring(0, 100) + '...';
                results.push({ page, snippet });
              }
            }
          } catch (error) {
            console.error(`Error fetching ${textUrl}:`, error);
          }
        }
        setSearchResults(results);
      };
      // Debounce if needed
      const timeoutId = setTimeout(searchText, 500);
      return () => clearTimeout(timeoutId);
    } else {
      setSearchResults([]);
    }
  }, [textSearchQuery, selectedDirIndex, totalPages, selectedDirInfo]);

  useEffect(() => {
    if (directories.length > 0 && selectedDirIndex >= 0) {
      async function fetchInfo() {
        const selectedDirectory = directories[selectedDirIndex];
        console.log('FetchInfo useEffect:', { selectedDirectory });
        if (selectedDirectory) {
          const info = await fetchPdfViewerInfo(selectedDirectory);
          console.log('Fetched selectedDirInfo:', info);
          setSelectedDirInfo(info);
        }
      }
      fetchInfo();
    }
  }, [directories, selectedDirIndex]);


  // Fetch info
  useEffect(() => {
    if (directories.length > 0 && selectedDirIndex >= 0) {
      async function fetchInfo() {
        const selectedDirectory = directories[selectedDirIndex];
        if (selectedDirectory) {
          const info = await fetchPdfViewerInfo(selectedDirectory);
          setSelectedDirInfo(info);
        }
      }
      fetchInfo();
    }
  }, [directories, selectedDirIndex]);

  // Update formats and pages
  useEffect(() => {
    if (selectedDirIndex >= 0 && directories.length > 0) {
      checkAvailableFormatsAndPages();
    }
  }, [selectedDirIndex, directories]);

  useEffect(() => {
    setCurrentPage(1);
    if (selectedDirIndex >= 0 && format !== 'pdf') {
      checkPageCountForFormat();
    }
  }, [format, selectedDirIndex]);

  // Dynamic height
  useEffect(() => {
    const updateHeight = () => {
      if (controlsRef.current && contentRef.current) {
        const controlsHeight = controlsRef.current.getBoundingClientRect().height;
        const windowHeight = window.innerHeight;
        const contentHeight = windowHeight - controlsHeight;
        contentRef.current.style.height = `${contentHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [selectedDirIndex, format]);

  // Scroll sync
  useEffect(() => {
    if (userScrolling.current || format === 'pdf' || selectedDirIndex < 0 || totalPages <= 1) return;

    if (format === 'text' || format === 'pdf_pages') {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        const pageHeight = iframe.contentWindow.document.body.scrollHeight / totalPages;
        iframe.contentWindow.scrollTo(0, (currentPage - 1) * pageHeight);
      }
    } else if (format === 'images') {
      const container = imageContainerRef.current;
      if (container) {
        const pageHeight = container.scrollHeight / totalPages;
        container.scrollTo(0, (currentPage - 1) * pageHeight);
      }
    }
  }, [currentPage, format, selectedDirIndex, totalPages]);

  // Search text
  useEffect(() => {
    if (textSearchQuery && selectedDirIndex >= 0 && selectedDirInfo['text']) {
      const searchText = async () => {
        const results: { page: number; snippet: string }[] = [];
        for (let page = 1; page <= totalPages; page++) {
          const textUrl = `${selectedDirInfo['text']}_page_${page}.txt`;
          try {
            const response = await fetch(textUrl);
            const text = await response.text();
            if (text.toLowerCase().includes(textSearchQuery.toLowerCase())) {
              const snippet = text.substring(0, 100) + '...';
              results.push({ page, snippet });
            }
          } catch (error) {
            console.error(`Error searching page ${page}:`, error);
          }
        }
        setSearchResults(results);
      };
      searchText();
    } else {
      setSearchResults([]);
    }
  }, [textSearchQuery, selectedDirIndex, totalPages, selectedDirInfo]);

  const checkAvailableFormatsAndPages = async (): Promise<void> => {
    if (selectedDirIndex < 0 || selectedDirIndex >= directoryLinks.length) return;
    setIsLoading(true);
    setTotalPages(0);
    setCurrentPage(1);

    for (let fmt of ['pdf', 'pdf_pages', 'text', 'images']) {
      const extension = fmt === 'text' ? 'txt' : fmt === 'images' ? 'png' : 'pdf';
      const url = get_content_link(fmt, selectedDirIndex, 1, extension);
      try {
        const response = await fetch(url);
        if (response.ok) {
          setFormat(fmt);
          if (fmt !== 'pdf') {
            await checkPageCountForFormat(fmt);
          }
          break;
        }
      } catch (error) {
        console.log(`${fmt} not available:`, error);
      }
    }
    setIsLoading(false);
  };

  const checkPageCountForFormat = async (fmt: any = format): Promise<void> => {
    if (selectedDirIndex < 0 || selectedDirIndex >= directoryLinks.length) return;
    const extension = fmt === 'text' ? 'txt' : fmt === 'images' ? 'png' : 'pdf';
    let page = 1;
    try {
      while (true) {
        const url = get_content_link(fmt, selectedDirIndex, page, extension);
        const response = await fetch(url);
        if (!response.ok) break;
        page++;
      }
      setTotalPages(page - 1);
    } catch (error) {
      console.error(`Error counting pages for ${fmt}:`, error);
    }
  };

  const get_content_link = (type: string, dirIndex: number, page: number, ext: string) => {
    if (dirIndex < 0 || dirIndex >= directoryLinks.length || !selectedDirInfo[type]) return '';
    const baseLink = selectedDirInfo[type];
    if (type === 'pdf') return baseLink;
    return `${baseLink}_page_${page}.${ext}`;
  };

  const handleError = (key: string) => {
    setErrorStates(prev => ({ ...prev, [key]: true }));
    console.log(`Error loading content: ${key}`);
  };

  const handlePageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const page = parseInt(e.target.value, 10);
    if (!isNaN(page) && page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleScroll = () => {
    if (format === 'pdf' || selectedDirIndex < 0 || totalPages <= 1) return;
    userScrolling.current = true;
    if (format === 'text' || format === 'pdf_pages') {
      const iframe = iframeRef.current;
      if (!iframe || !iframe.contentWindow) return;
      const scrollTop = iframe.contentWindow.scrollY || 0;
      const scrollHeight = iframe.contentWindow.document.body.scrollHeight || 0;
      const pageHeight = scrollHeight / totalPages;
      const newPage = Math.max(1, Math.min(totalPages, Math.floor(scrollTop / pageHeight) + 1));
      if (newPage !== currentPage) setCurrentPage(newPage);
    } else if (format === 'images') {
      const container = imageContainerRef.current;
      if (!container) return;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const pageHeight = scrollHeight / totalPages;
      const newPage = Math.max(1, Math.min(totalPages, Math.floor(scrollTop / pageHeight) + 1));
      if (newPage !== currentPage) setCurrentPage(newPage);
    }
    setTimeout(() => { userScrolling.current = false; }, 100);
  };

  const renderContent = (): React.ReactNode => {
    if (isLoading) return <div>Loading...</div>;
    if (selectedDirIndex < 0 || directories.length === 0) return <div>No directories found</div>;

    const extension = format === 'text' ? 'txt' : format === 'images' ? 'png' : 'pdf';
    const url = get_content_link(format, selectedDirIndex, currentPage, extension);
    const errorKey = `${format}-${selectedDirIndex}-${currentPage}`;

    if (format === 'images') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Top Bar for View Options */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
              background: '#f0f0f0',
              borderBottom: '1px solid #ccc'
            }}
          >
            <label>
              <input
                type="checkbox"
                checked={showThumbnails}
                onChange={() => setShowThumbnails(!showThumbnails)}
              />
              Thumbnails
            </label>
            <label>
              <input
                type="checkbox"
                checked={showText}
                onChange={() => setShowText(!showText)}
              />
              Text
            </label>
          </div>
          {/* Content Area */}
          <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
            {/* Thumbnail Column */}
            {showThumbnails && (
              <div
                style={{
                  width: '120px',
                  overflowY: 'auto',
                  borderRight: '1px solid #ccc',
                  padding: '5px',
                  flexShrink: 0
                }}
              >
                {Array.from({ length: totalPages }, (_, i) => {
                  const page = i + 1;
                  const thumbUrl = `${selectedDirInfo['images']}_page_${page}.png`;
                  return (
                    <div
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      style={{
                        cursor: 'pointer',
                        marginBottom: '5px',
                        border: page === currentPage ? '2px solid blue' : 'none'
                      }}
                    >
                      <Image
                        src={thumbUrl}
                        alt={`Thumbnail ${page}`}
                        width={100}
                        height={75}
                        unoptimized
                        onError={() => handleError(`images-${selectedDirIndex}-${page}`)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {/* Main Viewer */}
            <div style={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
              {/* Image Section - Scrollable with All Images */}
              <div
                ref={imageContainerRef}
                style={{ flex: showText ? 1 : 'auto', overflowY: 'auto', padding: '10px' }}
                onScroll={handleScroll}
              >
                {Array.from({ length: totalPages }, (_, i) => {
                  const page = i + 1;
                  const imgUrl = `${selectedDirInfo['images']}_page_${page}.png`;
                  const imgErrorKey = `images-${selectedDirIndex}-${page}`;
                  return (
                    <div
                      key={page}
                      style={{
                        marginBottom: '20px',
                        border: page === currentPage ? '2px solid blue' : 'none'
                      }}
                    >
                      {errorStates[imgErrorKey] ? (
                        <div>Failed to load image {page}</div>
                      ) : (
                        <Image
                          src={imgUrl}
                          alt={`Page ${page}`}
                          width={800}
                          height={600}
                          style={{ maxWidth: '100%', height: 'auto' }}
                          unoptimized
                          onError={() => handleError(imgErrorKey)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {/* Text Section */}
              {showText && (
                <div style={{ flex: 1, overflowY: 'auto', padding: '10px', borderLeft: '1px solid #ccc' }}>
                  {errorStates[`text-${selectedDirIndex}-${currentPage}`] ? (
                    <div>Failed to load text</div>
                  ) : (
                    <iframe
                      src={`${selectedDirInfo['text']}_page_${currentPage}.txt`}
                      width="100%"
                      height="100%"
                      title={`Text Page ${currentPage}`}
                      style={{ border: 'none' }}
                      onError={() => handleError(`text-${selectedDirIndex}-${currentPage}`)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    // Other formats
    switch (format) {
      case 'pdf':
        return errorStates[errorKey] ? (
          <div>Failed to load</div>
        ) : (
          <embed
            src={url}
            type="application/pdf"
            width="100%"
            height="100%"
            onError={() => handleError(errorKey)}
          />
        );
      case 'pdf_pages':
      case 'text':
        return errorStates[errorKey] ? (
          <div>Failed to load</div>
        ) : (
          <iframe
            ref={iframeRef}
            src={url}
            width="100%"
            height="100%"
            title={`Page ${currentPage}`}
            style={{ overflow: 'auto', border: 'none' }}
            onLoad={() => {
              if (iframeRef.current?.contentWindow) {
                iframeRef.current.contentWindow.removeEventListener('scroll', handleScroll);
                iframeRef.current.contentWindow.addEventListener('scroll', handleScroll);
              }
            }}
            onError={() => handleError(errorKey)}
          />
        );
      default:
        return <div>No content available</div>;
    }
  };

  return (
    <div className={styles['pdf-viewer']} style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div ref={controlsRef} className={styles.controls}>
        <div className={styles['controls-row']}>
          <div style={{ marginBottom: '10px' }}>
            <select
              value={selectedDirIndex}
              onChange={e => setSelectedDirIndex(parseInt(e.target.value))}
            >
              <option value={-1}>Select a document</option>
              {directories.map((dir, index) => (
                <option key={dir} value={index}>
                  {get_basename(dir)}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedDirIndex >= 0 && (
          <>
            <div className={styles['controls-row']}>
              <div style={{ marginBottom: '10px' }}>
                <select value={format} onChange={e => setFormat(e.target.value as FormatType)}>
                  {availableFormats.map(fmt => (
                    <option key={fmt} value={fmt}>
                      {fmt === 'pdf_pages' ? 'PDF Pages' : fmt.charAt(0).toUpperCase() + fmt.slice(1)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles['controls-row']}>
              <div style={{ marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="Search text in document..."
                  value={textSearchQuery}
                  onChange={(e) => setTextSearchQuery(e.target.value)}
                  style={{ width: '200px', padding: '5px' }}
                />
                {searchResults.length > 0 && (
                  <div style={{ maxHeight: '100px', overflowY: 'auto', background: '#fff', border: '1px solid #ccc' }}>
                    {searchResults.map((result) => (
                      <div
                        key={result.page}
                        onClick={() => setCurrentPage(result.page)}
                        style={{ padding: '5px', cursor: 'pointer', background: result.page === currentPage ? '#e0e0e0' : 'transparent' }}
                      >
                        Page {result.page}: {result.snippet}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles['controls-row']}>
              {format !== 'pdf' && totalPages > 0 && (
                <div className={styles.pagination}>
                  <button onClick={() => setCurrentPage(p => Math.max(1, p - 1))} disabled={currentPage === 1}>
                    Previous
                  </button>
                  <select value={currentPage} onChange={handlePageChange}>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                      <option key={page} value={page}>
                        Page {page}
                      </option>
                    ))}
                  </select>
                  <span>of {totalPages}</span>
                  <button onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))} disabled={currentPage === totalPages}>
                    Next
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div ref={contentRef} className={styles.content} style={{ flex: 1, overflow: 'hidden' }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default PdfViewer;